<template>
    <div v-if="table" id="marelist">
        <div class="row">
            <div class="col-auto ml-auto">
                <b-button @click="checkDateContract" variant="primary">{{ $t('action.ajouter_contract') }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'plus']"/></b-button>
            </div>
        </div>
        <div v-if="table_busy">
            <LoadingSpinner />
        </div>
        <div v-else class="row">
            <div v-if="contracts.length == 0" class="col-12 text-center">
                {{ $t('global.list_empty') }}
            </div>
            <div v-for="(season, index) in seasons" class="col-12" :key="season">
                <h3>
                    <a href="#" @click.prevent="openContractsList(season, index)">
                        {{ season }}
                        <font-awesome-icon :icon="['fal', 'angle-down']" v-if="selected_index.includes(index)" />
                        <font-awesome-icon :icon="['fal', 'angle-up']" v-else />
                    </a>
                </h3>
                <div v-if="index == 0" :class="'row ' + season + '_' + index">
                    <div v-for="contract in contracts[season]" class="col-12 col-lg-4 mb-2 mb-lg-3" :key="contract.contract_id">
                        <div class="mares" @click="goToContract(contract.contract_id)">
                            <span>
                                <span class="text-primary">{{ contract.season_label }} - {{ contract.contract_num }}</span>
                                <b>{{ contract.stallion_nom }} * {{ contract.mare_nom }}</b>
                                <i>{{ contract.sms_status }} - <span class="text-primary">{{ contract.avenant_status }}</span></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-else :class="'row d-none ' + season + '_' + index">
                    <div v-for="contract in contracts[season]" class="col-12 col-lg-4 mb-2 mb-lg-3" :key="contract.contract_id">
                        <div class="mares" @click="goToContract(contract.contract_id)">
                            <span>
                                <span class="text-primary">{{ contract.season_label }} - {{ contract.contract_num }}</span>
                                <b>{{ contract.stallion_nom }} * {{ contract.mare_nom }}</b>
                                <i>{{ contract.sms_status }} - <span class="text-primary">{{ contract.avenant_status }}</span></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <TiersModal ref="tiers_modal" @submit="tiersInfosChecked" />
    </div>
	<div v-else>
		<ContractShutter :contract_id="contract_id" :mare_id="mare_id"></ContractShutter>
	</div>
</template>

<script type="text/javascript">
	import ContractMixin from "@/mixins/Contract.js"
	import Common from '@/assets/js/common.js'
	import TiersMixin from "@/mixins/Tiers.js"
	import Vue from 'vue'
	import _groupBy from 'lodash/groupBy'

	export default {
		name: "ContractList",
		mixins: [ContractMixin, TiersMixin],
		props: ['mare_id', 'stallion_id'],
		data () {
			return {
				contracts: [],
				seasons: [],
				selected_index: [0],
				table: true,
				table_busy: false,
				processing: false
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.table_busy = true

				let contracts = []
				if(this.mare_id) {
					contracts = await this.getContractByTiersAndHorse(this.mare_id)
				}
				else if(this.stallion_id) {
					contracts = await this.getContractByTiersAndHorse(this.stallion_id)
				}
				else {
					contracts = await this.getContractByTiers()
				}
				this.contracts = await this.contracts_formatted(contracts)
				this.contracts = _groupBy(this.contracts, 'season_label')

				// Toutes les saisons
				this.seasons = Object.keys(this.contracts)
				this.seasons = this.seasons.sort(function(a, b) {
					return parseInt(b) - parseInt(a)
				})

				this.table_busy = false
			},
			async contracts_formatted(contracts) {
				let res = []
				await Common.asyncForEach(contracts, async (contract) => {
					let article = ""

					for (let i = 0; i < contract.avenant_articles.length; i++) {
						const ht = await Common.priceFormat(contract.avenant_articles[i].avenantarticles_ht / 100)

						if (contract.avenant_articles[i].condition) {
							article += this.getTrad(contract.avenant_articles[i].condition.conditions.contractconditions_label) + ": " + ht + "<br>"
						}
                        else if (contract.avenant_articles[i].type_condition) {
							article += this.getTrad(contract.avenant_articles[i].type_condition.contractconditions_label) + ": " + ht + "<br>"
                        }
					}

					res.push({
						contract_id: 				contract.contract_id,
						contract_num: 				(contract.contract_num == null) ? Vue.i18n.translate("monte.contrat_brouillon_non_numerote") : contract.contract_num,
						contract_created: 			contract.contract_created,
						tiers_rs: 					contract.tiers_rs,
						tiers_id: 					contract.tiers_id,
						mare_nom: 					contract.mare_nom,
						mare_id: 					contract.mare_id,
						stallion_id: 				contract.stallion_id,
						stallion_nom: 				contract.stallion_nom,
						cmep: 						contract.cmep,
						avenant_date_creation: 		contract.avenant_date_creation,
						avenant_date_signature: 	contract.avenant_date_signature,
						avenant_date_validation: 	contract.avenant_date_validation,
						season_label: 				contract.season_label,
						article: 					article,
						avenant_status_initial: 	contract.avenant_status,
						avenant_status: 			this.getTrad(contract.avenant_status),
						type_monte: 				contract.type_monte,
						stallion_stationnement: 	contract.stallion_stationnement,
						document_dn: 				contract.document_dn,
						document_as: 				contract.document_as,
						sms_status:					contract.sms_status
					})
				})

				return res
			},
			async checkDateContract() {
				this.processing = true
				this.contractPrivacyPolicy()
                    .then(res => {
                        if(!res || (res && (!res.start_contract || !res.end_contract))) {
							this.checkInfosTiers()
                            return
                        }
                        let now = new Date()
                        if(now >= new Date(res.start_contract) && now <= new Date(res.end_contract)) {
							this.checkInfosTiers()
                            return
                        }
                        this.failureToast(res.text_contract, true)
						this.processing = false
                    })
			},
			async checkInfosTiers() {
				let logged_tiers = await this.loadLoggedTiers()
				this.$refs.tiers_modal.openModal(logged_tiers)
			},
			async tiersInfosChecked(infos_tiers_filled) {
				if(infos_tiers_filled) {
					const contract = await this.addContract()
					this.contract_id = contract.contract_id
					this.table = false
				}
			},
			goToContract(contract_id) {
				this.$router.push({ name: 'Contract', params: {contract_id: contract_id} })
			},
			openContractsList(season, index) {
				if(document.getElementsByClassName(season + '_' + index)[0].classList.contains('d-none')) {
					document.getElementsByClassName(season + '_' + index)[0].classList.remove('d-none')
					if(!this.selected_index.includes(index)) {
						this.selected_index.push(index)
					}
				}
				else {
					document.getElementsByClassName(season + '_' + index)[0].classList.add('d-none')
					if(this.selected_index.includes(index)) {
						this.selected_index.splice(this.selected_index.indexOf(index), 1)
					}
				}
			}
		},
		components: {
			ContractForm : () => import('@/components/Contract/ContractForm'),
			ContractShutter : () => import('@/components/Contract/ContractShutter'),
            LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			TiersModal: () => import('@/components/Contract/InformationsTiersForm')
		},
	}
</script>